$thumb-size: 180px;
$wrap: 800px;

.citation {
  display: flex;
  margin: 15px 0;
  background: #f9f9f9;
  overflow: hidden;
  border-left: 3px solid rgb(207, 212, 214);
}


.citation::before {
  content: ' ';
  background:#f9f9f9;
  display: flex;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #1a92b0;
  margin: -20px -14px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

[data-dark="false"] .citation {
  background: #f9f9f9;
}

[data-dark="true"] .citation {
  background: #1d1c1c;
}

[data-dark="false"] .citation::before {
  background: #f9f9f9;
}

[data-dark="true"] .citation::before {
  background:#1d1c1c;
}


.citation-image {
  position: relative;
  width: $thumb-size;
  flex-shrink: 0;
  // box-shadow: var(--shadow);
}

.citation-image img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.citation-text {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 100%;
  height: min-content;
  padding: 20px;
  padding-left: 30px;
  text-align: left;
  overflow-wrap: break-word;
  z-index: 0;
}

.citation-title,
.citation-authors,
.citation-details,
.citation-description {
  width: 100%;
  line-height: calc(var(--spacing) - 0.4);
}

.citation-title {
  font-weight: var(--semi-bold);
  color: #228198;
}

.citation-text > .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--light-gray);
  opacity: 0.5;
  font-size: 30px;
  z-index: -1;
}

.citation-description {
  color: var(--gray);
}

.citation-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.citation-buttons .button {
  margin: 0;
}

.citation-text > .tags {
  display: inline-flex;
  justify-content: flex-start;
  margin: 0;
}

@media (max-width: $wrap) {
  .citation {
    flex-direction: column;
  }

  .citation-image {
    width: unset;
    height: $thumb-size;
  }
}
